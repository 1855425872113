import React, { useRef, useState } from "react";
import Swal from "sweetalert2";

function SubscribeButton({
  packs,
  addToCart,
  isLogged,
  IsCollectionId,
  packname,
}) {
  const [isHoveredFirstHalf, setIsHoveredFirstHalf] = useState(false);
  const [isHoveredSecondHalf, setIsHoveredSecondHalf] = useState(false);
  const [selectedPlans, setSelectedPlans] = useState({
    monthly: false,
    yearly: false,
  });

  // Create refs for both checkboxes
  const monthlyCheckboxRef = useRef(null);
  const yearlyCheckboxRef = useRef(null);

  // Handle hover for the first half (monthly)
  const handleFirstHalfMouseEnter = () => setIsHoveredFirstHalf(true);
  const handleFirstHalfMouseLeave = () => setIsHoveredFirstHalf(false);

  // Handle hover for the second half (yearly)
  const handleSecondHalfMouseEnter = () => setIsHoveredSecondHalf(true);
  const handleSecondHalfMouseLeave = () => setIsHoveredSecondHalf(false);

  // Handle checkbox change for both checkboxes
  const handleCheckboxChange = (type) => {
    // const loggedin = localStorage.getItem("loggedin");
    // if (loggedin==null || loggedin === "false") {
    //   Swal.fire({
    //     icon: 'warning',
    //     title: 'Please login first',
    //     text: 'You need to login to buy this package.',
    //     confirmButtonText: 'OK'
    //   });
    //   return;
    // }

    // Determine the opposite plan type
    //  const oppositeType = type === "monthly" ? "yearly" : "monthly";

    // Remove the opposite plan first
    // addToCart(packs, oppositeType, oppositeType === "monthly" ? packs.Monthly : packs.Yearly, IsCollectionId, "remove");

    // Update selected plans
    setSelectedPlans((prevSelectedPlans) => {
      const newSelection = {
        monthly: type === "monthly" ? true : false,
        yearly: type === "yearly" ? true : false,
      };

      // Add the selected plan to the cart
      setTimeout(() => {
        const selectedPlan = type === "monthly" ? packs.Monthly : packs.Yearly;
        addToCart(packs, type, selectedPlan, IsCollectionId, "add", packname);
      }, 0);

      return newSelection;
    });
  };

  // Handle span click for monthly plan to trigger checkbox click
  const handleMonthlySpanClick = () => {
    if (monthlyCheckboxRef.current) {
      monthlyCheckboxRef.current.click();
    }
  };

  // Handle span click for yearly plan to trigger checkbox click
  const handleYearlySpanClick = () => {
    if (yearlyCheckboxRef.current) {
      yearlyCheckboxRef.current.click();
    }
  };

  return (
    <div className="sub-buttons" style={{ display: "flex", height: "30px" }}>
      {packs.Monthly == 0 ? null : (
        <span
          onMouseEnter={handleFirstHalfMouseEnter}
          onMouseLeave={handleFirstHalfMouseLeave}
          style={{
            cursor: "pointer",
            backgroundColor: isHoveredFirstHalf ? "lightblue" : "#eb984e",
            color: isHoveredFirstHalf ? "black" : "white",
            padding: "2px",
            borderRadius: "5px 0 0 5px", // Rounded corners only on the left
            width: "80px", // Fixed width for the first half
            textAlign: "center",
            marginRight: "1px",
          }}
          onClick={handleMonthlySpanClick} // Trigger checkbox click when span is clicked
        >
          <input
            type="checkbox"
            name={`subscription-${packs.Id}-monthly`}
            ref={monthlyCheckboxRef} // Attach the ref to the monthly checkbox
            checked={selectedPlans.monthly}
            onChange={() => handleCheckboxChange("monthly")} // Handle checkbox change
            style={{ display: "none" }} // Hide the actual checkbox (optional)
          />
          <span style={{ fontSize: "12px" }}>
            {isHoveredFirstHalf ? (
              "Add to cart"
            ) : (
              <>
                <span style={{ color: "white", fontWeight: "bold" }}>$</span>
                {packs.Monthly}/mo
              </>
            )}
          </span>
        </span>
      )}
      {/* Monthly Plan */}

      {/* Yearly Plan */}
      {packs.Yearly == 0 ? null : (
        <span
          onMouseEnter={handleSecondHalfMouseEnter}
          onMouseLeave={handleSecondHalfMouseLeave}
          style={{
            cursor: "pointer",
            backgroundColor: isHoveredSecondHalf ? "lightgreen" : "#D67534",
            color: isHoveredSecondHalf ? "black" : "white",
            padding: "2px",
            borderRadius: "0 5px 5px 0", // Rounded corners only on the right
            width: "80px", // Fixed width for the second half
            textAlign: "center",
            marginLeft: "1px",
          }}
          onClick={handleYearlySpanClick} // Trigger checkbox click when span is clicked
        >
          <input
            type="checkbox"
            name={`subscription-${packs.Id}-yearly`}
            ref={yearlyCheckboxRef} // Attach the ref to the yearly checkbox
            checked={selectedPlans.yearly}
            onChange={() => handleCheckboxChange("yearly")} // Handle checkbox change
            style={{ display: "none" }} // Hide the actual checkbox (optional)
          />
          

          <span style={{ fontSize: "12px" }}>
            {isHoveredSecondHalf ? (
              "Add to cart"
            ) : (
              <>
                <span style={{ color: "white", fontWeight: "bold" }}>$</span>
                {packs.Yearly}/yr
              </>
            )}
          </span>
        </span>
      )}
    </div>
  );
}

export default SubscribeButton;
