import React, { useRef, useEffect, useState } from "react";

const ComingSoon = () => {
  const initialFeatures = [
    {
      title: "Competition Package",
      description:
        "Guaranteed to improve School Spelling skills and give a jumpstart for advancing in contests!",
    },
    {
      title: "ALWAYS FREE LISTS",
      description:
        "(Free sign up required for tracking progress) - Fry and Dolch sight words, SAT words, 1000+ High quality Spelling bee words - 5 difficulty categories",
    },
    {
      title: "Starter Pack  - Beginner 1,2",
      description:
        "Random word packs 1 & 2 - High quality spelling bee words for beginner spellers",
    },
  ];

  const [features, setFeatures] = useState(initialFeatures);

  useEffect(() => {
    // No need to start/stop auto scroll with this approach since it's handled by CSS
  }, []);

  return (
    <>
      <style>
        {`
          @keyframes scrollRightToLeft {
            0% {
                transform: translateX(100%);
            }

            100% {
                transform: translateX(-100%);
            }
          }

          #scrolling-container {
            display: flex;
            white-space: nowrap;
            animation: scrollRightToLeft 30s linear infinite; /* Scroll over 30s */
            margin-bottom:10px;
          }

          .item {
            flex: 0 0 auto;
            padding: 10px;
            /* Optional styling for the feature items */
            background: #90e9dd;
            border-radius: 8px;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
            margin: 0 10px;
          }

          .feature-title {
            font-size: 1.5rem;
            margin: 0;
            color: #ffffff;
            text-align: center;
            display: inline-block;
            text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.7);
          }

          .feature-description {
            font-size: 1rem;
            margin: 0;
            color: #666;
          }
        `}
      </style>

      <div
        style={{
          position: "relative",
          width: "100%",
          overflow: "hidden",
        }}
      >
        <div
          id="scrolling-container"
          style={{
            display: "flex",
            gap: "10px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {/* Render the features list twice to create the seamless loop effect */}
          {[...features, ...features].map((feature, index) => (
            <div key={index} className="item">
              <h4 className="feature-title blink" style={{ color: "orange" }}>
                {feature.title}
              </h4>
              <p className="feature-description">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default ComingSoon;
