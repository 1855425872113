import React, { useState, useEffect } from "react";
import { Autocomplete, TextField, IconButton } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import ClearIcon from "@mui/icons-material/Clear";

import axios from "axios";
import { FaPlus } from "react-icons/fa";
import { Add } from "@mui/icons-material";

const AutocompleteComponent = ({
  fieldName,
  row,
  handleSave,
  defaultValue,
  onClose,updateNewPP,
}) => {
  const [editValue, setEditValue] = useState(defaultValue || ""); // Holds the selected value or typed value
  const [inputValue, setInputValue] = useState(""); // Holds the current input
  const [options, setOptions] = useState([]); // Holds the options loaded from the API

  // Function to load data from API based on fieldName
  const loadData = () => {
    const apiUrl = `https://s3napi.s3nsoftware.com/api/QuizDatas/GetAcValues?word=${row.Word}&type=${fieldName}&pos=${row.pos}`;

    //    console.log('log ',apiUrl);
    axios
      .post(apiUrl)
      .then((response) => {
        setOptions(response.data); // Assuming response.data is the array of options
        if (response.data.length > 0 && !editValue) {
          setEditValue(response.data[0]); // Set the first option as the default value if no default value is set
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  // Load data when fieldName or row changes
  useEffect(() => {
    if (["etym", "defn", "sent", "pos", "Pron"].includes(fieldName)) {
      loadData();
    }
  }, [fieldName, row]);

  // Handle input change (manual typing)
  const handleTextChange = (event, newValue) => {
    setInputValue(newValue); // Update input value
    setEditValue(newValue || inputValue); // Update selected or typed value
  };

  // Handle value change (when an option is selected from the list)
  const handleValueChange = (event, newValue) => {
    const modifiedValue = newValue
      ? newValue.substring(6)
      : inputValue.substring(6);

    setEditValue(modifiedValue); // Update selected or typed value
  };

  // Handle save action when clicking the Save icon
  const handleSaveClick = (pp) => {
    const valueToSave = editValue || inputValue; // Save either the selected value or the typed input
    if (valueToSave) {
      // Add the new value at the 0 index of the options list
      setOptions((prevOptions) => [valueToSave, ...prevOptions]);
      updateNewPP(pp);
      handleSave(row.Id, fieldName, valueToSave); // Pass the value to the save function
    }
  };



  const handleCloseClick = () => {
    onClose && onClose(); // Trigger close action if provided
  };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {/* Autocomplete for field types like etym, synonym, antonym, etc. */}
      {["etym", "defn", "sent", "pos", "Pron"].includes(fieldName) ? (
        <Autocomplete
          options={options}
          value={editValue} // Value to display in the field
          onChange={handleValueChange} // Update value when an option is selected
          inputValue={inputValue} // Current input value (typed)
          onInputChange={handleTextChange} // Update input value as user types
          renderInput={(params) => <TextField {...params} label={fieldName} />} // Dynamic label
          autoFocus
          fullWidth
        />
      ) : null}

      {/* Save button (appears when there is a selected or typed value) */}
      {(editValue || inputValue) && (
        <>
          {["etym", "defn", "sent"].includes(fieldName) ? (
            <>
              <IconButton size="20"
                onClick={()=>handleSaveClick(false)} // Save the value when clicking the Save icon
                color="primary"
              >
                <SaveIcon />
              </IconButton>
              <IconButton color="primary" onClick={handleCloseClick}>
                <ClearIcon />
              </IconButton>
            </>
          ) : ["pos", "Pron"].includes(fieldName) ? (
            <>
              <IconButton
                onClick={()=>handleSaveClick(true)} // Save the value when clicking the Save icon
                color="secondary"
              >
                <Add />
              </IconButton>
              <IconButton
                onClick={()=>handleSaveClick(false)} // Save the value when clicking the Save icon
                color="secondary"
              >
                <SaveIcon />
              </IconButton>
              <IconButton color="secondary" onClick={handleCloseClick}>
                <ClearIcon />
              </IconButton>
            </>
          ) : null}
        </>
      )}
    </div>
  );
};

export default AutocompleteComponent;
