import React, { useEffect, useState } from "react";
import styles from "./Add.module.css";

import banner from "../assets/img/spelling_banner.png";
import { usePreference } from "../context/UserContext";

const AdvertisementModel = (props) => {
  const [progress, setProgress] = useState(100); // Start at 100%

  const closeModal = () => {
    props.updateIsOpen(false);
  };

  const {
    updateIsLoginOpen,
    updateIsSignUpOpen,
    showCart,
    updateShowCart,
    cart,
    updateCart,
  } = usePreference();

  // Check if the cart already contains the subscription package
  const isAlreadySubscribed = cart.some(
    (item) => item.packId === 19 && item.type === "yearly"
  );

  useEffect(() => {
    if (props.open && !isAlreadySubscribed) {
      const duration = 20000; // 20 seconds
      const interval = 50; // Update progress every 50ms
      let elapsed = 0;

      const timer = setInterval(() => {
        elapsed += interval;
        const remaining = Math.max(100 - (elapsed / duration) * 100, 0); // Reverse progress
        setProgress(remaining);

        if (elapsed >= duration) {
          clearInterval(timer); // Stop the timer when duration ends
          closeModal(); // Automatically close the modal
        }
      }, interval);

      return () => clearInterval(timer); // Cleanup timer on unmount or modal close
    } else {
      setProgress(100); // Reset progress when modal is closed
    }
  }, [props.open]);

  const handleclick = () => {
    let fixed499pack = [
      {
        pack: {
          Id: 24,
          Package: "1 Year Pack",
          CatId: "1",
          Price: 499,
          IsSubscribed: false,
          Discription:
            "",
          PackageCode: null,
          Monthly: 0,
          Yearly: 499,
          Categories: [],
          IsMonthly: false,
          SpOrder: 0,
          IsFree: false,
          Expired: "0001-01-01T00:00:00",
        },
        packId: 24,
        type: "yearly",
        price: 499,
        isCollection: true,
        packname: "1 Year Oack",
      },
    ];
    updateCart(fixed499pack);
    updateShowCart(true);
    props.updateIsOpen(false);
    localStorage.setItem("cart", JSON.stringify(fixed499pack)); // Save to localStorage
  };
  return (
    <div className={styles.modal_container}>
      {props.open &&  !isAlreadySubscribed &&(
        <div className={styles.modal}>
          <div className={styles.modal_content}>
            
            <div className={styles.progress_bar_container}>
              <div
                className={styles.progress_bar}
                style={{ width: `${progress}%` }}
              ></div>
            </div>
            <div >
            <span className={styles.close} onClick={closeModal}>
              &times;
            </span>
              <img className={styles.image_container}
                src={banner}
                alt="Banner"
              />
            </div>
            <div >
              <button onClick={handleclick} className={styles.subscribe_button}>
                Subscribe
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdvertisementModel;
