import React, { useState, useRef } from 'react';
import { TextField, IconButton } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import ClearIcon from '@mui/icons-material/Clear';

const EditableTextField = ({ value, onSave, onClose, label, type = 'text' }) => {
    const [editValue, setEditValue] = useState(value);
    const saveButtonRef = useRef(null);
    const closeButtonRef = useRef(null);

    const handleTextChange = (event) => {
        setEditValue(event.target.value);
    };

    const handleBlur = (event) => {
        // Check if the click was on the save or close button
        if (
            (saveButtonRef.current && saveButtonRef.current.contains(event.relatedTarget)) ||
            (closeButtonRef.current && closeButtonRef.current.contains(event.relatedTarget))
        ) {
            return; // Don't trigger onSave if Save or Close button was clicked
        }
        onSave(editValue); // Trigger save when clicking outside
    };

    const handleSaveClick = () => {
        onSave(editValue); // Trigger save manually
    };

    const handleCloseClick = () => {
        onClose && onClose(); // Trigger close action if provided
    };

    return (
        <TextField
            type={type}
            label={label}
            value={editValue}
            onChange={handleTextChange}
            //onBlur={handleBlur}
            fullWidth
            InputProps={{
                endAdornment: (
                    <>
                        <IconButton
                            ref={saveButtonRef} // Reference for save button
                            onClick={handleSaveClick}
                        >
                            <SaveIcon />
                        </IconButton>
                        <IconButton
                            ref={closeButtonRef} // Reference for close button
                            onClick={handleCloseClick}
                        >
                            <ClearIcon />
                        </IconButton>
                    </>
                ),
            }}
        />
    );
};

export default EditableTextField;
