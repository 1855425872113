import React from "react";
import {
  FaEnvelope,
  FaWhatsapp,
  FaFacebook,
  FaTwitter,
  FaInstagram,
  FaLinkedin,
} from "react-icons/fa";

const NavbarTop = () => {
  return (
    <>
      <style>
        {`
          /* Main container for the navbar */
          .navbar-top-container {
            display: flex;
            justify-content: center; /* Center outer container */
            padding: 10px 20px;
            background-color: #333;
            color: white;
          }

          /* Inner container aligned to the right */
          .right-aligned-container {
            display: flex;
            justify-content: flex-end; /* Align content to the right */
            width: 100%; /* Take full width */
            max-width: 1200px; /* Limit the maximum width */
            align-items: center;
            flex-wrap: wrap; /* Enable wrapping for smaller screens */
          }

          /* Contact info section */
          .contact-info {
            display: flex;
            flex-direction: row; /* Horizontal layout */
            gap: 20px; /* Spacing between contact items */
            align-items: center;
            text-align: right; /* Align text to the right */
          }

          .contact-item {
            display: flex;
            align-items: center;
            font-size: 14px;
            gap: 5px;
          }

          .icon {
            font-size: 16px;
          }

          /* Social media icons */
          .social-icons {
            display: flex;
            gap: 10px; /* Spacing between social icons */
            align-items: center;
            justify-content: center;
          }

          .social-icon {
            font-size: 16px;
            color: white; /* Default color for icons */
          }

          .social-icon.facebook {
            color: #4267b2;
          }

          .social-icon.twitter {
            color: #1da1f2;
          }

          .social-icon.instagram {
            color: #c13584;
          }

          .social-icon.linkedin {
            color: #0077b5;
          }

          /* Responsive styles */
          @media (max-width: 576px) {
            .right-aligned-container {
              justify-content: center; /* Center align for small screens */
            }

            .contact-info {
              flex-direction: column; /* Stack items vertically */
              text-align: center; /* Center-align text for small screens */
              gap: 10px; /* Increase gap */
            }

            .contact-item {
              font-size: 12px; /* Adjust font size for smaller screens */
            }

            .social-icons {
              gap: 5px; /* Reduce spacing between icons */
            }
          }
        `}
      </style>

      <div className="navbar-top-container">
        {/* Right-Aligned Container */}
        <div className="right-aligned-container">
          <div className="contact-info">
            <label className="contact-item">
              <FaEnvelope className="icon email-icon" style={{color:'orange'}}/>
              spelling@s3nsoftware.com
            </label>
            <label className="contact-item">
              <FaWhatsapp className="icon whatsapp-icon" style={{color:'green'}} />
              +1 (512) 299-7106
            </label>
            {/* <div className="social-icons">
              <a
                href="https://facebook.com"
                target="_blank"
                rel="noopener noreferrer"
                className="social-icon facebook"
              >
                <FaFacebook />
              </a>
              <a
                href="https://twitter.com"
                target="_blank"
                rel="noopener noreferrer"
                className="social-icon twitter"
              >
                <FaTwitter />
              </a>
              <a
                href="https://instagram.com"
                target="_blank"
                rel="noopener noreferrer"
                className="social-icon instagram"
              >
                <FaInstagram />
              </a>
              <a
                href="https://linkedin.com"
                target="_blank"
                rel="noopener noreferrer"
                className="social-icon linkedin"
              >
                <FaLinkedin />
              </a>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default NavbarTop;
