import React, { useEffect, useState, useRef } from "react";
import Swal from "sweetalert2";
import SearchBar from "../common/SearchBar";
import "./NewNavbar.css"; // Import the custom CSS for hover functionality
import BrandLogo from "../assets/img/spelllogodark.png";
import { usePreference } from "../context/UserContext";
import { ShoppingCartOutlined } from "@mui/icons-material";

import { FaEnvelope, FaWhatsapp } from "react-icons/fa";
import NavbarTop from "./navbar/NavbarTop";


const NewNavbar = ({ setShowModal }) => {
  const {
    isNavVisible,
    isLogged,
    updateIsLoginOpen,
    updateIsLogged,
    updateIsSignUpOpen,
    upateForgotPwdOpen,
    showCart,
    updateShowCart,
    cart,
    updateCart,
    profilePhoto,
    updateProfilePhoto,
  } = usePreference();

  const [username, setUsername] = useState("");
  const [userRole, setUserRole] = useState("");
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const [isAccountMenuOpen, setIsAccountMenuOpen] = useState(true);
  const [isAdminMenuOpen, setIsAdminMenuOpen] = useState(true);
  const sidebarRef = useRef(null); // Ref for the sidebar

  useEffect(() => {
    const loggedin = localStorage.getItem("loggedin");
    updateIsLogged(loggedin);

    const storedUsername = localStorage.getItem("username") || "User";
    const storedUserRole = localStorage.getItem("userrole") || "User";
    const storedProfilePhoto = localStorage.getItem("userphoto");

    const profilePhotoURL =
      storedProfilePhoto && storedProfilePhoto !== "null"
        ? `https://s3nsoftware.com/profilepics/${storedProfilePhoto}.jpg`
        : "";

    setUsername(storedUsername);
    setUserRole(storedUserRole);
    updateProfilePhoto(profilePhotoURL);
  }, [isLogged, updateIsLogged, profilePhoto]);

  useEffect(() => {
    // Close sidebar if clicked outside of it
    const handleClickOutside = (event) => {
      if (
        isMobileNavOpen &&
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target)
      ) {
        setMobileNavOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isMobileNavOpen]);

  const handleLogin = () => {
    updateIsLoginOpen(true);
  };

  const handleSign = () => {
    updateIsSignUpOpen(true);
  };

  const handleLogout = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "Once signed out, you will need to log in again.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, sign me out!",
      cancelButtonText: "Cancel",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Signed Out!",
          text: "You have been signed out successfully.",
          icon: "success",
          timer: 2000,
          showConfirmButton: false,
        });

        updateIsLogged(false);
        localStorage.clear();
        window.location.href = "/";
      } else {
        Swal.fire({
          title: "You are still signed in!",
          icon: "info",
          timer: 2000,
          showConfirmButton: false,
        });
      }
    });
  };

  const handleForgot = () => {
    updateIsLoginOpen(false);
    upateForgotPwdOpen(true);
    updateIsSignUpOpen(false);
    setMobileNavOpen(!isMobileNavOpen);
  };

  const toggleMobileNav = () => {
    setMobileNavOpen(!isMobileNavOpen);
  };

  const toggleAccountMenu = () => {
    setIsAccountMenuOpen(!isAccountMenuOpen);
  };

  const toggleAdminMenu = () => {
    setIsAdminMenuOpen(!isAdminMenuOpen);
  };

  return isNavVisible ? (
    <> 
    <NavbarTop/>
      <nav className="navbar navbar-expand-md py-3">
      
        <div className="container">

          <a className="navbar-brand d-flex align-items-center" href="/">
            <img
              src={BrandLogo}
              alt="Brand Logo"
              style={{ width: "80px", height: "50px" }}
            />
          </a>

          <div className="search-bar-section">
            <SearchBar />
          </div>

          <button
            className="navbar-toggler"
            type="button"
            aria-label="Toggle navigation"
            onClick={toggleMobileNav}
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          {/* Mobile Sidebar */}
          <div
            className={`mobile-nav ${isMobileNavOpen ? "open" : ""}`}
            ref={sidebarRef} // Sidebar ref for click detection
          >
            <button className="close-btn" onClick={toggleMobileNav}>
              &times;
            </button>

            <div className="sidebar-content">
              {/* Cart Icon in Mobile View */}
              {cart && cart.length > 0 && (
                <div
                  className="mobile-cart-icon"
                  style={{ marginTop: "20px", textAlign: "center" }}
                >
                  <button
                    className="dropdown-item"
                    style={{ position: "relative" }} // Set relative positioning for the button
                    onClick={() => updateShowCart(true)}
                  >
                    <ShoppingCartOutlined
                      style={{ color: "black", fontSize: "32px" }}
                    />
                    <span
                      style={{
                        position: "absolute",
                        top: "-5px",

                        backgroundColor: "red",
                        color: "white",
                        borderRadius: "50%",
                        padding: "1px 6px",
                        fontSize: "12px",
                        fontWeight: "bold",
                      }}
                    >
                      {cart.length}
                    </span>
                  </button>
                </div>
              )}
              {/* Profile Section */}
              {isLogged === "true" && (
                <div className="profile-section">
                  <img
                    src={profilePhoto || "https://via.placeholder.com/50"}
                    alt="Profile"
                    className="rounded-circle"
                    width="50"
                    height="50"
                  />
                  {/* <p className="username">{username}</p> */}

                  <div
                    className="username-dropdown"
                    onClick={toggleAccountMenu}
                  >
                    <span>
                      <p className="username">{username}</p>
                    </span>
                    <i
                      className={`dropdown-icon ${
                        isAccountMenuOpen ? "open" : ""
                      }`}
                    ></i>
                  </div>

                  {isAccountMenuOpen && (
                    <div className="account-settings">
                      <ul className="nav-list">
                        <li>
                          <a href="/profile">Profile</a>
                        </li>
                        <li>
                          <a href="#" onClick={handleForgot}>
                            Change Password
                          </a>
                        </li>
                        <li>
                          <a href="#" onClick={handleLogout}>
                            Logout
                          </a>
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              )}

              {/* Admin Section */}
              {(userRole === "Admin" || userRole === "SuperAdmin") && (
                <div className="admin-section ">
                  <div className="username-dropdown " onClick={toggleAdminMenu}>
                    <span>Admin</span>
                    <i
                      className={`dropdown-icon ${
                        isAdminMenuOpen ? "open" : ""
                      }`}
                    ></i>
                  </div>

                  {isAdminMenuOpen && (
                    <div className="admin-menu">
                      <ul className="nav-list">
                        <li>
                          <a href="/admin">Admin Panel</a>
                        </li>
                        <li>
                          <a href="/feedbacks">Manage Feedbacks</a>
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              )}

              {/* Feedback Section */}
              {isLogged === "true" && userRole === "User" && (
                <div className="feedback-section text-center">
                  <a
                    href="#"
                    onClick={() => {
                      toggleMobileNav();
                      setShowModal(true);
                    }}
                  >
                    Feedback
                  </a>
                </div>
              )}

              {/* Login for non-logged users */}
              {isLogged !== "true" && (
                <div className="login-section">
                  <button
                    className="btn btn-secondary"
                    onClick={() => {
                      toggleMobileNav();
                      handleLogin();
                    }}
                  >
                    Login
                  </button>

                  <button
                    className="btn btn-secondary"
                    onClick={() => {
                      toggleMobileNav();
                      handleSign();
                    }}
                  >
                    SignUp
                  </button>
                </div>
              )}
            </div>
          </div>

          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav ms-auto align-items-center text-center ">
              {/* Admin Section */}
              {(userRole === "Admin" || userRole === "SuperAdmin") && (
                <li className="nav-item dropdown profile-dropdown text-center">
                  <a
                    className="nav-link dropdown-toggle d-flex align-items-center "
                    href="#"
                    id="adminDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Admin
                  </a>

                  <ul
                    className="dropdown-menu dropdown-menu-end"
                    aria-labelledby="adminDropdown"
                  >
                    <li>
                      <a className="dropdown-item" href="/admin">
                        Admin Panel
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="/feedbacks">
                        Manage Feedbacks
                      </a>
                    </li>
                  </ul>
                </li>
              )}
              {/* User Feedback */}
              {isLogged === "true" && userRole === "User" && (
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="#"
                    onClick={() => setShowModal(true)}
                  >
                    Feedback
                  </a>
                </li>
              )}

              {isLogged === "true" && (
                <li className="nav-item dropdown profile-dropdown">
                  <a
                    className="nav-link dropdown-toggle d-flex align-items-center"
                    href="#"
                    id="profileDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      src={
                        profilePhoto && profilePhoto.trim() !== ""
                          ? profilePhoto
                          : "https://picsum.photos/200"
                      }
                      alt="Profile"
                      className="rounded-circle me-2"
                      width="40"
                      height="40"
                    />
                    {username}
                  </a>
                  <ul
                    className="dropdown-menu dropdown-menu-end"
                    aria-labelledby="profileDropdown"
                  >
                    <li>
                      <a className="dropdown-item" href="/profile">
                        Profile
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        style={{ cursor: "pointer" }}
                        onClick={handleForgot}
                      >
                        Change Password
                      </a>
                    </li>
                    <li>
                      <button className="dropdown-item" onClick={handleLogout}>
                        Logout
                      </button>
                    </li>
                  </ul>
                </li>
              )}

              {isLogged !== "true" && (
                <li className="nav-item">
                  <>
                    <a
                      className="btn btn-secondary ms-md-2"
                      role="button"
                      href="#"
                      onClick={handleLogin}
                    >
                      Login
                    </a>
                    <a
                      className="btn btn-secondary ms-md-2"
                      role="button"
                      href="#"
                      onClick={handleSign}
                    >
                      SignUp
                    </a>
                  </>
                </li>
              )}

              {cart && cart.length > 0 ? (
                <li>
                  <button
                    className="dropdown-item"
                    style={{ position: "relative" }} // Set relative positioning for the button
                    onClick={() => updateShowCart(true)}
                  >
                    <ShoppingCartOutlined
                      style={{ color: "white", fontSize: "32px" }}
                    />

                    {/* Cart length indicator */}
                    <span
                      style={{
                        position: "absolute",
                        top: "-5px", // Adjust the positioning relative to the ShoppingCart icon
                        right: "-5px",
                        backgroundColor: "red",
                        color: "white",
                        borderRadius: "50%",
                        padding: "1px 6px",
                        fontSize: "12px",
                        fontWeight: "bold",
                      }}
                    >
                      {cart.length}
                    </span>
                  </button>
                </li>
              ) : (
                <></>
              )}
            </ul>
            
           
          </div>
        </div>
      </nav>
    </>
  ) : null;
};

export default NewNavbar;
